import Vue from "vue";
//import Vue from '../vendor/vue';
import { Carousel3d, Slide } from "vue-carousel-3d";
import $ from "jquery";
import "../vendor/fancybox";

//Vue.config.productionTip = false;
const vueBlock = document.querySelector("#example");
if (vueBlock) {
  new Vue({
    el: "#example",
    data: {
      isAutoplay: true,
      slidesPotfolio: {
        0: {
          id: 0,
          img: "img/portfolio/sm/15.jpg",
          imgBig: "img/portfolio/lg/15.jpg",
        },
        1: {
          id: 1,
          img: "img/portfolio/sm/16.jpg",
          imgBig: "img/portfolio/lg/16.jpg",
        },
        2: {
          id: 2,
          img: "img/portfolio/sm/17.jpg",
          imgBig: "img/portfolio/lg/17.jpg",
        },
        3: {
          id: 3,
          img: "img/portfolio/sm/18.jpg",
          imgBig: "img/portfolio/lg/18.jpg",
        },
        4: {
          id: 4,
          img: "img/portfolio/sm/19.jpg",
          imgBig: "img/portfolio/lg/19.jpg",
        },
        5: {
          id: 5,
          img: "img/portfolio/sm/20.jpg",
          imgBig: "img/portfolio/lg/20.jpg",
        },
        6: {
          id: 6,
          img: "img/portfolio/sm/21.jpg",
          imgBig: "img/portfolio/lg/21.jpg",
        },
        7: {
          id: 7,
          img: "img/portfolio/sm/22.jpg",
          imgBig: "img/portfolio/lg/22.jpg",
        },
        8: {
          id: 8,
          img: "img/portfolio/sm/24.jpg",
          imgBig: "img/portfolio/lg/24.jpg",
        },
        9: {
          id: 9,
          img: "img/portfolio/sm/25.jpg",
          imgBig: "img/portfolio/lg/25.jpg",
        },
        10: {
          id: 10,
          img: "img/portfolio/sm/1.jpg",
          imgBig: "img/portfolio/lg/1.jpg",
        },
        11: {
          id: 11,
          img: "img/portfolio/sm/2.jpg",
          imgBig: "img/portfolio/lg/2.jpg",
        },
        12: {
          id: 12,
          img: "img/portfolio/sm/3.jpg",
          imgBig: "img/portfolio/lg/3.jpg",
        },
        13: {
          id: 13,
          img: "img/portfolio/sm/7.jpg",
          imgBig: "img/portfolio/lg/7.jpg",
        },
        14: {
          id: 14,
          img: "img/portfolio/sm/10.jpg",
          imgBig: "img/portfolio/lg/10.jpg",
        },
        15: {
          id: 15,
          img: "img/portfolio/sm/12.jpg",
          imgBig: "img/portfolio/lg/12.jpg",
        },
        16: {
          id: 16,
          img: "img/portfolio/sm/13.jpg",
          imgBig: "img/portfolio/lg/13.jpg",
        },
        17: {
          id: 17,
          img: "img/portfolio/sm/14.jpg",
          imgBig: "img/portfolio/lg/14.jpg",
        },
      },
    },
    components: {
      Carousel3d,
      Slide,
    },
    methods: {
      onSlideClick() {
        alert("Остановись слайдер!!!");
        this.isAutoplay = false;
      },
    },
  });

  $().fancybox({
    selector: ".slider__link--img:visible",
    loop: true,
    btnTpl: {
      arrowLeft:
        '<button id="prev" data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="Назад"></button>',
      arrowRight:
        '<button id ="next" data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="Вперед"></button>',
    },
  });
}
