import jQuery from 'jquery';
(function($) {
  $.fn.validationEngineLanguage = function() {};
  $.validationEngineLanguage = {
    newLang: function() {
      $.validationEngineLanguage.allRules = {
        required: {
          regex: 'none',
          alertText: '* Необходимо заполнить',
          alertTextCheckboxMultiple: '* Вы должны выбрать вариант',
          alertTextCheckboxe: '* Необходимо отметить',
        },
        requiredInFunction: {
          func: function(field, rules, i, options) {
            return field.val() == 'test' ? true : false;
          },
          alertText: '* Значением поля должно быть test',
        },
        minSize: {
          regex: 'none',
          alertText: '* Минимум ',
          alertText2: ' символа(ов)',
        },
        maxSize: {
          regex: 'none',
          alertText: '* Максимум ',
          alertText2: ' символа(ов)',
        },
        groupRequired: {
          regex: 'none',
          alertText: '* Вы должны заполнить одно из следующих полей',
        },
        min: { regex: 'none', alertText: '* Минимальное значение ' },
        max: { regex: 'none', alertText: '* Максимальное значение ' },
        past: { regex: 'none', alertText: '* Дата до ' },
        future: { regex: 'none', alertText: '* Дата от ' },
        maxCheckbox: {
          regex: 'none',
          alertText: '* Нельзя выбрать столько вариантов',
        },
        minCheckbox: {
          regex: 'none',
          alertText: '* Пожалуйста, выберите ',
          alertText2: ' опцию(ии)',
        },
        equals: { regex: 'none', alertText: '* Поля не совпадают' },
        creditCard: {
          regex: 'none',
          alertText: '* Неверный номер кредитной карты',
        },
        phone: {
          regex: /^([\+][0-9]{1,3}([ \.\-])?)?([\(][0-9]{1,6}[\)])?([0-9 \.\-]{1,32})(([A-Za-z \:]{1,11})?[0-9]{1,4}?)$/,
          alertText: '* Неправильный формат телефона',
        },
        email: {
          regex: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
          alertText: '* Неверный формат email',
        },
        integer: { regex: /^[\-\+]?\d+$/, alertText: '* Только целые числа' },
        number: {
          regex: /^[\-\+]?((([0-9]{1,3})([,][0-9]{3})*)|([0-9]+))?([\.]([0-9]+))?$/,
          alertText: '* Неправильное число с плавающей точкой',
        },
        date: {
          regex: /^(0[1-9]|[12][0-9]|3[01])[ \.](0[1-9]|1[012])[ \.](19|20)\d{2}$/,
          alertText: '* Неправильная дата (должно быть в ДД.MM.ГГГГ формате)',
        },
        ipv4: {
          regex: /^((([01]?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))[.]){3}(([0-1]?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))$/,
          alertText: '* Неправильный IP-адрес',
        },
        url: {
          regex: /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
          alertText: '* Неправильный URL',
        },
        onlyNumberSp: { regex: /^[0-9\ ]+$/, alertText: '* Только числа' },
        onlyLetterSp: {
          regex: /^[a-zA-Z\u0400-\u04FF\ \']+$/,
          alertText: '* Только буквы',
        },
        onlyLetterNumber: {
          regex: /^[0-9a-zA-Z\u0400-\u04FF]+$/,
          alertText: '* Запрещены специальные символы',
        },
        ajaxUserCall: {
          url: 'ajaxValidateFieldUser',
          extraData: 'name=eric',
          alertText: '* Этот пользователь уже занят',
          alertTextLoad: '* Проверка, подождите...',
        },
        ajaxNameCall: {
          url: 'ajaxValidateFieldName',
          alertText: '* Это имя уже занято',
          alertTextOk: '* Это имя доступно',
          alertTextLoad: '* Проверка, подождите...',
        },
        validate2fields: { alertText: '* Пожалуйста, введите HELLO' },
      };
    },
  };
  $.validationEngineLanguage.newLang();
})(jQuery);
