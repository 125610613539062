import $ from 'jquery';
import '../vendor/jquery.validationEngine';
import '../vendor/jquery.validationEngine-ru';
import '../vendor/jquery.flexslider';
import '../vendor/jquery.easydropdown';
import '../vendor/navigation';
import '../vendor/jquery.magnific-popup';
import {checkGeo, checkProjectId} from '../modules/dynamicPhones';

/* function setMarkers(e) {
    for (var o = { url: "./img/map-marker.svg" }, n = 0; n < beaches.length; n++) {
        var t = beaches[n],
            i = new google.maps.Marker({ position: { lat: t[1], lng: t[2] }, map: e, icon: o, title: t[0], zIndex: t[3] }),
            a = t[0],
            p = new google.maps.InfoWindow;
        google.maps.event.addListener(i, "click", function(o, n, t) { return function() { t.setContent(n), t.open(e, o) } }(i, a, p))
    }
}

function initMap() {
    //console.log("initMap");
    var e = [59.90747806421724, 30.32478849999991];
    setMarkers(new google.maps.Map(document.getElementById("map"), { zoom: zoom, scrollwheel: !1, center: { lat: e[0], lng: e[1] } }))
} */
var modalConf = { type: "inline", fixedContentPos: !1, fixedBgPos: !0, overflowY: "auto", closeBtnInside: !0, preloader: !1, midClick: !0, removalDelay: 300 },
    openModal = function(e) { $.magnificPopup.open(Object.assign(modalConf, { items: { src: e, type: "inline"} })) },
    openStockModal = function() {
        var e;
        window.innerWidth > 767 ? (e = 6e4 ) : (e = 12e3 ), setTimeout(function() { $.magnificPopup.open({ type: "inline", fixedContentPos: !0, fixedBgPos: !0, overflowY: "auto", closeBtnInside: !0, preloader: !1, midClick: !0, removalDelay: 300, items: { src: "#nyear-2018-stock" }})}, e)
    };
$(document).ready(function() {

    openStockModal(), $("select").easyDropDown(), $(".select-project-right button").click(function() { return $.magnificPopup.open({ type: "inline", midClick: !0, items: { src: "#popup-order" }, callbacks: { open: function() { $("#popup-order").find('[name="form_title"]').val("Посчитайте мой проект") } } }), !1 }), $(".we-build-item-content a, .header-top-info .call").click(function() {
        var e = $(this),
            o = e.attr("form-title"),
            n = e.attr("action"),
            t = e.attr("goal");
        return $.magnificPopup.open({
            type: "inline",
            midClick: !0,
            items: { src: "#popup-call" },
            callbacks: {
                open: function() {
                    var e = $("#popup-call");
                    e.find('[name="form_title"]').val(o), e.find('[name="action"]').val(n), e.find('[name="goal"]').val(t)
                }
            }
        }), !1
    }), $(".download-catalog-content a").click(function() {
        var e = $(this),
            o = e.attr("form-title"),
            n = e.attr("action"),
            t = e.attr("goal");
        return $.magnificPopup.open({
            type: "inline",
            midClick: !0,
            items: { src: "#popup-catalog" },
            callbacks: {
                open: function() {
                    var e = $("#popup-catalog");
                    e.find('[name="form_title"]').val(o), e.find('[name="action"]').val(n), e.find('[name="goal"]').val(t)
                }
            }
        }), !1
    }), $("#menu").navigation(), $("a.scroll").click(function() { $.scrollTo($(".div"), 800, { offset: -90 }) }), $("#top").click(function() { return $("body, html").animate({ scrollTop: 100 }, 800), !1 }), $(".menu").navigation(), $(window).scroll(function() {
        $(window).scrollTop() > 10 ? $("header .header-top").css("position", "fixed") : $("header .header-top").css("position", "fixed");
        $(window).scrollTop() > 10 ? $("header .header-logo").addClass("header-min") : $("header .header-logo").removeClass("header-min");
        $(window).scrollTop() > 10 ? $("header .call").addClass("header-min") : $("header .call").removeClass("header-min");
        $(window).scrollTop() > 10 ? $("header .header-time").addClass("header-min") : $("header .header-time").removeClass("header-min");
        $(window).scrollTop() > 10 ? $(".header-top-info").addClass("header-bigfont") : $(".header-top-info").removeClass("header-bigfont");
    }), $(".mobile-menu li a").on("click", function() { $(".mobile-menu input[type=checkbox]").prop("checked", !1) })
});

  //  Переливание текста
  $(".anim-text-flow").html(function (i, html) {
    var chars = $.trim(html).split("");

    return "<span>" + chars.join("</span><span>") + "</span>";
  });
var zoom;
window.innerWidth, zoom = 15;
var marker, beaches = [
    ["Российская Федерация, 196247, г. Санкт-Петербург, ул. Заозерная, д. 8 к. 2, офис 202", 59.90747806421724, 30.32478849999991, 4]
];
//initMap(),
 $(".house_project_open1").on('click',function() {
    var e = $(this),
        o = (e.attr("id"),
        e.attr("title")),
        n = {},
        t = e.attr("oldPrice"),
        i = e.attr("bazePrice");
    n.shrinkage90x140Price = e.attr("shrinkage_90x140_Price"), n.full90x140Price = e.attr("full_90x140_Price");
    var a = e.attr("size"),
        p = e.attr("area"),
        r = e.attr("images").split(";"),
        l = e.attr("subject"),
        c = e.attr("goal");
     return $.magnificPopup.open({
        type: "inline",
        midClick: !0,
        items: { src: "#popup-house" },
        callbacks: {
            open: function() {
                var e = $("#popup-house");
                e.find('.flexslider').append('<ul class="popup-house-img slides"></ul>');
                l ? e.find('[name="subject"]').val(l) : e.find('[name="subject"]').val("Проекты домов ( блок )"), c ? e.find('[name="goal"]').val(c) : e.find('[name="goal"]').val("request_site_forms"), $("#popup-house").find('[name="form_title"]').val(o), $("#popup-house").find(".popup-house-img").html(""), $("#popup-house .house-title").text(o), $("#popup-house .project_size").text(a), $("#popup-house .project_area").text(p);
                var s = "" + t;
                "по запросу" !== t && (s = t + " ₽"), $("#popup-house .project_old_price").text(s);
                var u = "" + i;
                "по запросу" !== i && (u = i + " ₽"), $("#popup-house .project_baze_price").text(u);
                var m = { shrinkage90x140Price: "Под усадку от", full90x140Price: "Под ключ от"},
                    d = Object.keys(n).reduce(function(e, o) { return n[o] ? e + "<li><span>" + m[o] + " " + n[o] + " ₽</span></li>" : "" }, "");
                $(".complectation_variants").html(d);
                for (var f = 0; f < r.length - 1; f++) {$("#popup-house").find(".popup-house-img").append(' <li class="slide"><img src = "img/' + r[f] + '" /></li>');}
                $(".flexslider").flexslider({
                    animation: "slide",
                    animationLoop: true,
                    smoothHeight: false,
                    slideshow: true,
                    //controlNav: 'thumbnails'
                  });
                },
            close: function() {
                $('.flexslider').removeData();
                $('.flexslider').empty();
                }
            }
        }
    ), !1;

});

$(".house_project_open").on('click',function() {
    var target = $(this);
    var infoCard = target.prev();
    var cardTitle = target.siblings('.projects-item-title').text();
    var minPrice = infoCard.find('.min-price').text();
    var maxPrice = infoCard.find('.max-price').text();
    var size = infoCard.find('.size').text();
    var area = infoCard.find('.area').text();
    var productId = target.attr("id");
    var feedId = checkGeo();


        var o = (target.attr("id"),
        target.attr("title")),
        n = {},
        t = target.attr("oldPrice"),
        i = target.attr("bazePrice");
    n.shrinkage90x140Price = minPrice,
    n.full90x140Price = maxPrice;
    var r = target.attr("images").split(";"),
        l = target.attr("subject"),
        c = target.attr("goal");
     return $.magnificPopup.open({
        type: "inline",
        midClick: !0,
        items: { src: "#popup-house" },
        callbacks: {
            open: function() {
                var e = $("#popup-house");
                e.find('.flexslider').append('<ul class="popup-house-img slides"></ul>');
                l ? e.find('[name="subject"]').val(l) : e.find('[name="subject"]').val("Проекты домов ( блок )"), c ? e.find('[name="goal"]').val(c) : e.find('[name="goal"]').val("request_site_forms"), $("#popup-house").find('[name="form_title"]').val(o), $("#popup-house").find(".popup-house-img").html(""), $("#popup-house .house-title").text(cardTitle), $("#popup-house .project_size").text(size), $("#popup-house .project_area").text(area);
                var s = "" + t;
                "по запросу" !== t && (s = t + " ₽"), $("#popup-house .project_old_price").text(s);
                var u = "" + i;
                "по запросу" !== i && (u = i + " ₽"), $("#popup-house .project_baze_price").text(minPrice);
                var m = { shrinkage90x140Price: "Под усадку от", full90x140Price: "Под ключ от"},
                    d = Object.keys(n).reduce(function(e, o) { return n[o] ? e + "<li><span>" + m[o] + " " + n[o] + " ₽</span></li>" : "" }, "");
                $(".complectation_variants").html(d);
                for (var f = 0; f < r.length - 1; f++) {$("#popup-house").find(".popup-house-img").append(' <li class="slide"><img src = "img/' + r[f] + '" /></li>');}
                $(".flexslider").flexslider({
                    animation: "slide",
                    animationLoop: true,
                    smoothHeight: true,
                    slideshow: true,
                    start: function(slider){
                        $('.flexslider').resize();
                    }
                    //controlNav: 'thumbnails'
                  });
                  _tmr.push({
                    type: 'itemView',
                    productid: productId,
                    pagetype: 'product',
                    list: feedId,
                    });
                },
            close: function() {
                $('.flexslider').removeData();
                $('.flexslider').empty();
                }
            }
        }
    ), !1;

});

checkProjectId();


