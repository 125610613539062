import $ from 'jquery';
// Подстановка телефонных номеров
function getUrlVars() {
  var vars = [],
      hash,
      hashes,
      href;

  href = window.location.href;
  hashes = window.location.href.slice(href.indexOf('?') + 1).split('&');
  for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
  }
  return vars;
}

let phoneFlag = getUrlVars()['utm_geo']? getUrlVars()['utm_geo'] : '';
var feedId= '';
if (phoneFlag === '') {
  feedId = Math.floor(Math.random() * 2) + 1;
}
if (phoneFlag === 'moscow' ||  phoneFlag === '') {
  document.querySelector('.header-title--dynamic').textContent ='в любой точке России';
}

if (phoneFlag === 'moscow') {
  document.querySelector('.spb').classList.add('phone-hide');
  document.querySelector('.contacts-content_address--spb').classList.add('phone-hide');
  document.querySelector('.contacts-tel--spb').classList.add('phone-hide');
  document.querySelector('.contacts-tel--regions').classList.add('phone-hide');
  feedId = '2';
}

if (phoneFlag === 'petersburg') {
  document.querySelector('.moscow').classList.add('phone-hide');
  document.querySelector('.header-title--dynamic').textContent = 'Санкт-Петербург и область';
  document.querySelector('.contacts-content_address--msk').classList.add('phone-hide');
  document.querySelector('.contacts-tel--msk').classList.add('phone-hide');
  document.querySelector('.contacts-tel--regions').classList.add('phone-hide');
  feedId = '1';
}

function checkGeo() {
  let phoneFlag = getUrlVars()['utm_geo']? getUrlVars()['utm_geo'] : '';
  if (phoneFlag === 'moscow') {
    feedId = '2';
  } else if (phoneFlag === 'petersburg') {
    feedId = '1';
  }
  else  if (phoneFlag == '') { feedId = Math.floor(Math.random() * 2) + 1;}
  return feedId;
}

function checkProjectId() {
  const projectID = window.location.pathname.slice(1);
  if (
    projectID.length > 0
  ) {
    $('#' + projectID).trigger("click");
  }
}

const projectID = window.location.pathname.slice(1);
const projectPrefix = projectID.substr(0, 2);

if (
  projectID.length > 0 &&
  (projectPrefix === "db")
) {
  //console.log("projectID:", projectID);
  $(`.${projectID}`).trigger("click");
}


export {checkGeo, checkProjectId};