import './modules/map';
import './modules/main';
import './modules/dynamicPhones';
import './modules/am-script';
import './modules/slider';
import counter from './modules/counter';
import LazyLoad from "vanilla-lazyload";

window.addEventListener('DOMContentLoaded', () => {
  var lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
  });
  counter();
});