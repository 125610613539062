import $ from 'jquery';
        // Функция ymaps.ready() будет вызвана, когда
        // загрузятся все компоненты API, а также когда будет готово DOM-дерево.
        var spb = document.querySelector('.contacts-content_address--spb');
        var msk = document.querySelector('.contacts-content_address--msk');
        var myMap = null;
        spb.addEventListener('click', initSpb);
        msk.addEventListener('click', initMsk);
        ymaps.ready(initSpb);

        function initSpb() {
            if (myMap) {
                myMap.destroy();
                myMap = null;
            } else {

            }
            // Создание карты.
            // https://tech.yandex.ru/maps/doc/jsapi/2.1/dg/concepts/map-docpage/
            if (!myMap) {
                myMap = new ymaps.Map("map", {
                    // Координаты центра карты.
                    // Порядок по умолчнию: «широта, долгота».
                    center: [59.907171, 30.321527],
                    // Уровень масштабирования. Допустимые значения:
                    // от 0 (весь мир) до 19.
                    zoom: 16,
                    // Элементы управления
                    // https://tech.yandex.ru/maps/doc/jsapi/2.1/dg/concepts/controls/standard-docpage/
                    controls: [
                        "zoomControl", // Ползунок масштаба
                        "fullscreenControl" // Полноэкранный режим
                    ]
                });

                var collection = new ymaps.GeoObjectCollection({}, {
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map-marker.svg',
                    iconImageSize: [20, 26],
                    iconImageOffset: [-10, -26]
                });

                // ON RESIZE
                //Get curent center and zoom
                var pixelCenter = myMap.getGlobalPixelCenter('map');
                //console.log(pixelCenter);

                //
                function onResizeMap() {
                    if (window.innerWidth > 992) {
                        //Set New center
                        myMap.setCenter([59.907171, 30.321527]);
                        var pixelCenter2 = myMap.getGlobalPixelCenter('map');
                        //console.log(pixelCenter2);
                    } else {
                        myMap.setCenter([59.907618, 30.324767]);
                    }
                }
                onResizeMap();

                window.addEventListener('resize', function () {
                    onResizeMap();
                });

                // Добавление метки
                // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/Placemark-docpage/
                var myPlacemark = new ymaps.Placemark(
                    [59.907478, 30.324788], {
                        // Хинт показывается при наведении мышкой на иконку метки.
                        hintContent: "Заозёрная ул., 8, корп. 2",
                        // Балун откроется при клике по метке.
                        balloonContent: "<div style='text-align: center;'>Пестовский лесокомбинат<br>г. Санкт-Петербург<br>ул. Заозёрная, 8 к2, офис 202<br>8 (812) 603-72-22</div>"
                    }, {
                        // Опции.
                        // Необходимо указать данный тип макета.
                        iconLayout: 'default#image',
                        // Своё изображение иконки метки.
                        iconImageHref: 'img/map-marker.svg',
                        // Размеры метки.
                        iconImageSize: [70, 70],
                        // Смещение левого верхнего угла иконки относительно
                        // её "ножки" (точки привязки).
                        iconImageOffset: [-35, -70]
                    }
                );

                // После того как метка была создана, добавляем её на карту.
                myMap.geoObjects.add(myPlacemark);
                myMap.behaviors.disable("scrollZoom");
            }
        };

        function initMsk() {
            if (myMap) {
                myMap.destroy();
                myMap = null;
            }

            if (!myMap) {
                // Создание карты.
                // https://tech.yandex.ru/maps/doc/jsapi/2.1/dg/concepts/map-docpage/
                myMap = new ymaps.Map("map", {
                    // Координаты центра карты.
                    // Порядок по умолчнию: «широта, долгота».
                    center: [55.801835, 37.595402],
                    // Уровень масштабирования. Допустимые значения:
                    // от 0 (весь мир) до 19.
                    zoom: 16,
                    // Элементы управления
                    // https://tech.yandex.ru/maps/doc/jsapi/2.1/dg/concepts/controls/standard-docpage/
                    controls: [
                        "zoomControl", // Ползунок масштаба
                        "fullscreenControl" // Полноэкранный режим
                    ]
                });

                var collection = new ymaps.GeoObjectCollection({}, {
                    iconLayout: 'default#image',
                    iconImageHref: '/img/map-marker.svg',
                    iconImageSize: [70, 70],
                    iconImageOffset: [-35, -70]
                });

                // ON RESIZE
                //Get curent center and zoom
                var pixelCenter = myMap.getGlobalPixelCenter('map');
                //console.log(pixelCenter);

                //
                function onResizeMap() {
                    if (window.innerWidth > 992) {
                        //Set New center
                        myMap.setCenter([55.801835, 37.595402]);
                        var pixelCenter2 = myMap.getGlobalPixelCenter('map');
                        //console.log(pixelCenter2);
                    } else {
                        myMap.setCenter([55.800939, 37.592821]);
                    }
                }
                onResizeMap();

                window.onresize = function () {
                    onResizeMap();
                };

                // Добавление метки
                // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/Placemark-docpage/
                var myPlacemark = new ymaps.Placemark(
                    [55.800909, 37.592698], {
                        // Хинт показывается при наведении мышкой на иконку метки.
                        hintContent: "Складочная ул., д. 1, стр. 18, подъезд 3",
                        // Балун откроется при клике по метке.
                        balloonContent: "<div style='text-align: center;'>Пестовский лесокомбинат<br> г. Москва<br>ул. Складочная, д. 1, стр. 18, подъезд 3, этаж 2, офис 209<br> 8 (499) 380-77-33</div>"
                    }, {
                        // Опции.
                        // Необходимо указать данный тип макета.
                        iconLayout: 'default#image',
                        // Своё изображение иконки метки.
                        iconImageHref: 'img/map-marker.svg',
                        // Размеры метки.
                        iconImageSize: [70, 70],
                        // Смещение левого верхнего угла иконки относительно
                        // её "ножки" (точки привязки).
                        iconImageOffset: [-35, -70]
                    }
                );

                // После того как метка была создана, добавляем её на карту.
                myMap.geoObjects.add(myPlacemark);
                myMap.behaviors.disable("scrollZoom");
            }
        }