import jQuery from 'jquery';
var _tmr = window._tmr || (window._tmr = []);
function addHideParam(e) {
  if (e.find(".am-hide-block").length) return console.log("amHideBlock:  exist"), null;
  e = jQuery(e);
  var t, n = "ahp-value";
  t = ["utm_medium", "utm_source", "utm_campaign", "utm_term", "utm_content", "block", "placement", "position", "adposition", "source", "network"], jQuery("<div>").attr({
    type: "hidden",
    class: "am-hide-block"
  }).appendTo(e);
  var a = jQuery(".am-hide-block"),
    i = "";
    if (ga) {
      ga(function (e) {
        i = e.get("clientId")
      }), jQuery("<input>").attr({
        type: "hidden",
        name: "cid",
        value: i
      }).appendTo(a), t.forEach(function (e, t) {
        n = jQuery.getUrlVar(e), jQuery("<input>").attr({
          type: "hidden",
          name: e,
          value: n
        }).appendTo(a)
      })
    }

}
var _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
  return typeof e
} : function (e) {
  return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e
};
! function (e) {
  "function" == typeof define && define.amd ? define(["jquery"], e) : e("object" == ("undefined" == typeof exports ? "undefined" : _typeof(exports)) ? require("jquery") : jQuery)
}(function (e) {
  var t, n = navigator.userAgent,
    a = /iphone/i.test(n),
    i = /chrome/i.test(n),
    o = /android/i.test(n);
  e.mask = {
    definitions: {
      9: "[0-9]",
      a: "[A-Za-z]",
      "*": "[A-Za-z0-9]"
    },
    autoclear: !0,
    dataName: "rawMaskFn",
    placeholder: "_"
  }, e.fn.extend({
    caret: function (e, t) {
      var n;
      if (0 !== this.length && !this.is(":hidden")) return "number" == typeof e ? (t = "number" == typeof t ? t : e, this.each(function () {
        this.setSelectionRange ? this.setSelectionRange(e, t) : this.createTextRange && (n = this.createTextRange(), n.collapse(!0), n.moveEnd("character", t), n.moveStart("character", e), n.select())
      })) : (this[0].setSelectionRange ? (e = this[0].selectionStart, t = this[0].selectionEnd) : document.selection && document.selection.createRange && (n = document.selection.createRange(), e = 0 - n.duplicate().moveStart("character", -1e5), t = e + n.text.length), {
        begin: e,
        end: t
      })
    },
    unmask: function () {
      return this.trigger("unmask")
    },
    mask: function (n, r) {
      var l, c, u, s, f, d, p, m;
      if (!n && this.length > 0) {
        l = e(this[0]);
        var h = l.data(e.mask.dataName);
        return h ? h() : void 0
      }
      return r = e.extend({
        autoclear: e.mask.autoclear,
        placeholder: e.mask.placeholder,
        completed: null
      }, r), c = e.mask.definitions, u = [], s = p = n.length, f = null, e.each(n.split(""), function (e, t) {
        "?" == t ? (p--, s = e) : c[t] ? (u.push(new RegExp(c[t])), null === f && (f = u.length - 1), s > e && (d = u.length - 1)) : u.push(null)
      }), this.trigger("unmask").each(function () {
        function l() {
          if (r.completed) {
            for (var e = f; d >= e; e++)
              if (u[e] && _[e] === h(e)) return;
            r.completed.call(R)
          }
        }

        function h(e) {
          return r.placeholder.charAt(e < r.placeholder.length ? e : 0)
        }

        function g(e) {
          for (; ++e < p && !u[e];);
          return e
        }

        function v(e) {
          for (; --e >= 0 && !u[e];);
          return e
        }

        function y(e, t) {
          var n, a;
          if (!(0 > e)) {
            for (n = e, a = g(t); p > n; n++)
              if (u[n]) {
                if (!(p > a && u[n].test(_[a]))) break;
                _[n] = _[a], _[a] = h(a), a = g(a)
              } T(), R.caret(Math.max(f, e))
          }
        }

        function b(e) {
          var t, n, a, i;
          for (t = e, n = h(e); p > t; t++)
            if (u[t]) {
              if (a = g(t), i = _[t], _[t] = n, !(p > a && u[a].test(i))) break;
              n = i
            }
        }

        function k() {
          var e = R.val(),
            t = R.caret();
          if (m && m.length && m.length > e.length) {
            for (w(!0); t.begin > 0 && !u[t.begin - 1];) t.begin--;
            if (0 === t.begin)
              for (; t.begin < f && !u[t.begin];) t.begin++;
            R.caret(t.begin, t.begin)
          } else {
            for (w(!0); t.begin < p && !u[t.begin];) t.begin++;
            R.caret(t.begin, t.begin)
          }
          l()
        }

        function j() {
          w(), R.val() != C && R.change()
        }

        function x(e) {
          if (!R.prop("readonly")) {
            var t, n, i, o = e.which || e.keyCode;
            m = R.val(), 8 === o || 46 === o || a && 127 === o ? (t = R.caret(), n = t.begin, i = t.end, i - n == 0 && (n = 46 !== o ? v(n) : i = g(n - 1), i = 46 === o ? g(i) : i), Q(n, i), y(n, i - 1), e.preventDefault()) : 13 === o ? j.call(this, e) : 27 === o && (R.val(C), R.caret(0, w()), e.preventDefault())
          }
        }

        function S(t) {
          if (!R.prop("readonly")) {
            var n, a, i, r = t.which || t.keyCode,
              c = R.caret();
            if (!(t.ctrlKey || t.altKey || t.metaKey || 32 > r) && r && 13 !== r) {
              if (c.end - c.begin != 0 && (Q(c.begin, c.end), y(c.begin, c.end - 1)), n = g(c.begin - 1), p > n && (a = String.fromCharCode(r), u[n].test(a))) {
                if (b(n), _[n] = a, T(), i = g(n), o) {
                  var s = function () {
                    e.proxy(e.fn.caret, R, i)()
                  };
                  setTimeout(s, 0)
                } else R.caret(i);
                c.begin <= d && l()
              }
              t.preventDefault()
            }
          }
        }

        function Q(e, t) {
          var n;
          for (n = e; t > n && p > n; n++) u[n] && (_[n] = h(n))
        }

        function T() {
          R.val(_.join(""))
        }

        function w(e) {
          var t, n, a, i = R.val(),
            o = -1;
          for (t = 0, a = 0; p > t; t++)
            if (u[t]) {
              for (_[t] = h(t); a++ < i.length;)
                if (n = i.charAt(a - 1), u[t].test(n)) {
                  _[t] = n, o = t;
                  break
                } if (a > i.length) {
                Q(t + 1, p);
                break
              }
            } else _[t] === i.charAt(a) && a++, s > t && (o = t);
          return e ? T() : s > o + 1 ? r.autoclear || _.join("") === jQuery ? (R.val() && R.val(""), Q(0, p)) : T() : (T(), R.val(R.val().substring(0, o + 1))), s ? t : f
        }
        var R = e(this),
          _ = e.map(n.split(""), function (e, t) {
            return "?" != e ? c[e] ? h(t) : e : void 0
          }),
          jQuery = _.join(""),
          C = R.val();
        R.data(e.mask.dataName, function () {
          return e.map(_, function (e, t) {
            return u[t] && e != h(t) ? e : null
          }).join("")
        }), R.one("unmask", function () {
          R.off(".mask").removeData(e.mask.dataName)
        }).on("focus.mask", function () {
          if (!R.prop("readonly")) {
            clearTimeout(t);
            var e;
            C = R.val(), e = w(), t = setTimeout(function () {
              R.get(0) === document.activeElement && (T(), e == n.replace("?", "").length ? R.caret(0, e) : R.caret(e))
            }, 10)
          }
        }).on("blur.mask", j).on("keydown.mask", x).on("keypress.mask", S).on("input.mask paste.mask", function () {
          R.prop("readonly") || setTimeout(function () {
            var e = w(!0);
            R.caret(e), l()
          }, 0)
        }), i && o && R.off("input.mask").on("input.mask", k), w()
      })
    }
  })
}), jQuery.extend({
  getUrlVars: function () {
    var e, t, n, a = [];
    n = window.location.href, t = window.location.href.slice(n.indexOf("?") + 1).split("&");
    for (var i = 0; i < t.length; i++) e = t[i].split("="), a.push(e[0]), a[e[0]] = e[1];
    return a
  },
  getUrlVar: function (e) {
    return jQuery.getUrlVars()[e]
  }
});
if (ga) {
  ga(function (e) {
    var t = e.get("clientId");
    jQuery('input[name="cid"]').val(t)
  })
}; jQuery("input[name='request[Телефон]']").mask("+7 ( 999 ) 999 - 99 - 99"), jQuery(".ajax_form").on("submit", function (e) {
  e.preventDefault();
  var t = jQuery(this),
    n = 0;
  if (t.find(".rfield").each(function () {
      var e = jQuery(this);
      0 == e.val().length ? (n++, e.addClass("invalid")) : e.removeClass("invalid")
    }), jQuery(this).validationEngine("validate", {
      promptPosition: "topLeft",
      showOneMessage: !0,
      maxErrorsPerField: 1,
      scroll: !1
    })) {
    jQuery(t).children(".form_result")[0];
  } else n++;
  if (0 == n) {
    addHideParam(t);
    var a = jQuery(t).find('input[name="goal"]').val(),
      i = new FormData(this),
      o = jQuery(this).serialize();
    jQuery.ajax({
      url: "https://alpham.pro/leads/pplk/send-to-mail.php",
      async: !0,
      type: "POST",
      data: i,
      processData: !1,
      contentType: !1,
      success: function (e) {
        jQuery.ajax({
          type: "POST",
          data: o,
          url: "https://alpham.pro/leads/pplk/send-to-amo.php",
          success: function (e) {
            console.log("amoRes: ", e)
          }
        }), e.length > 0 && (jQuery.magnificPopup.close(), jQuery.magnificPopup.open({
          type: "inline",
          midClick: !0,
          items: {
            src: "#popup-thanks"
          }
        }), gtag("event", "sendform", {
          event_category: a,
          event_action: "send",
        }), yaCounter45475677.reachGoal(a),
         console.log(a + "-> send"), console.log("amoRes: ", e),
         _tmr.push({"type":"reachGoal","id":3247104,"goal":"mytarget_application"})

         )
      },
      dataFilter: function (e, t) {
        return e
      }
    })
  }
  return !1
});